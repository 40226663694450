<template>
  <div class="invoice">
    <page-nav title="我的发票模板" />
    <div class="invoice-list">
      <van-swipe-cell v-for="item in list" :key="item.id">
        <div class="invoice-list-item">
          <div class="invoice-info">
            <div class="name">{{item.title}}</div>
            <div class="type">
              <van-tag v-if="item.isDefault == 1" plain type="primary" color="red" class="default-tag">默认</van-tag>
              <span class="type" v-if="item.invoiceType == 2">增值税专用发票</span>
              <span class="type" v-else>增值税普通发票</span>
            </div>
          </div>
          <div class="edit">
            <van-button size="default" @click="onEdit(item)">编辑</van-button>
          </div>
        </div>
        <template #right>
          <van-button square text="删除" type="danger" class="cell-right-button" @click="onDelete(item)" />
        </template>
      </van-swipe-cell>
      
    </div>
    <div class="fix-bar">
      <div class="fix-cnt">
        <van-button type="info" round block @click="onAdd">新增发票模板</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Fetch from '../../common/fetch';
import { GetInvoiceList, DeleteInvoice } from '../../common/interface';
import { Toast, SwipeCell, Tag, Dialog } from 'vant';
import PageNav from '@/components/PageNav.vue';
Vue.use(Toast);
Vue.use(SwipeCell);
Vue.use(Tag);
Vue.use(Dialog);

export default {
  name: 'Invoice',
  components: { PageNav },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      Fetch.post(GetInvoiceList).then(res => {
        this.list = res || [];
      }).catch(err => {
        Toast(err.msg || '请求失败');
      })
    },

    onEdit(item) {
      this.$router.push('/setting/invoice/edit?id=' + item.id);
    },

    onDelete(item) {
      Dialog.confirm({
        title: '确认要删除此发票信息吗？',
        confirmButtonText: '删除',
      })
      .then(() => {
        const params = {
          id: item.id
        };
        Fetch.post(DeleteInvoice, params).then(() => {
          this.fetchList();
        }).catch((err) => {
          Toast(err.msg || '请求失败');
        });
      }).catch(() => {
      });
    },

    onAdd() {
      this.$router.push('/setting/invoice/edit');
    },
  },
}
</script>

<style lang="scss" scoped>
.invoice {
  .fix-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
    z-index: 120;
    background: #fff;
    .fix-cnt {
      padding: 10px 16px 8px;
    }
  }
}

.invoice-list {
  background: #fff;
  .invoice-list-item {
    position: relative;
    padding: 10px 50px 10px 10px;
    .invoice-info {
      overflow: hidden;
      font-size: 14px;
      color: #666;
      .name {
        color: #333;
        font-size: 16px;
        font-weight: bold;
        margin-top: 5px;
      }
      .type {
        margin-top: 5px;
        .default-tag {
          margin-right: 8px;
        }
      }
    }
    .edit {
      position: absolute;
      width: 50px;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: red;
      button {
        color: red;
        font-size: 15px;
      }
    }
  }
}

.cell-right-button {
  height: 100%;
}
</style>